<template>
  <div style="margin: 20px">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card>
          <el-form :model="form" v-loading="loading" :rules="rules" ref="form">
            <el-form-item label="ФИО" prop="fio">
              <el-input v-model="form.fio"></el-input>
            </el-form-item>
            <el-form-item label="Текст для подстановки">
              <el-input v-model="form.prefix"></el-input>
            </el-form-item>
            <el-form-item label="Является вице-губернатором">
              <br>
              <el-switch
                v-model="form.is_person"
                active-color="#13ce66"
                inactive-color="#ff4949" />
            </el-form-item>
            <el-form-item
              label="Файл"
              prop="file_id">
              <file-upload v-if="!loading" v-model="form.photo_file_id" @delete="form.photo_file_id = null"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save" :disabled="lockSaveButton">
                {{ isCreate ? 'Создать' : 'Сохранить' }}
              </el-button>
              <el-button v-if="form.is_deleted && !isCreate" type="success" @click="recoverActivity"
                         :disabled="lockRecoveryButton">Восстановить
              </el-button>
              <el-button v-if="!form.is_deleted && !isCreate" type="danger" @click="deleteActivity"
                         :disabled="lockDeleteButton">Удалить
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <label>Связанные ИОГВ</label>
          <simple-table style="margin-top: 10px" name="linkedIOGV" :columns="iogvColumns" :list="iogvData" v-loading="loading"/>
        </el-card>
      </el-col>
    </el-row>
    <el-card style="margin-top: 10px">
      <label>История изменений</label>
      <simple-table style="margin-top: 10px" name="historyGovernor" :columns="columns" :list="historyData" v-loading="loading"/>
    </el-card>
    <el-card style="margin-top: 10px">
      <label>История изменений привязок ИОГВ</label>
      <simple-table style="margin-top: 10px" name="historyGovernor" :columns="linkedColumns" :list="linkedHistoryData" v-loading="loading"/>
    </el-card>
  </div>
</template>

<script>
import {
  getViceGovernor,
  saveViceGovernor,
  deleteViceGovernor,
  recoverViceGovernor,
  getViceGovernorHistory
} from '@/api/dictionaries'
import SimpleTable from '@/components/SimpleTable'
import FileUpload from '@/components/FileUpload/Picture'

export default {
  components: {
    SimpleTable,
    FileUpload
  },
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false,
      lockSaveButton: false,
      lockDeleteButton: false,
      lockRecoveryButton: false,
      isCreate: false,
      historyData: [],
      iogvData: [],
      linkedHistoryData: [],
      columns: [
        {
          field: 'action_tstamp',
          type: 'date',
          label: 'Время изменения'
        },
        {
          field: 'value.fio',
          label: 'ФИО'
        },
        {
          field: 'editor_fio',
          label: 'Кто изменил'
        },
        {
          field: 'value.is_deleted',
          label: 'Удален'
        }
      ],
      iogvColumns: [
        {
          field: 'ebosp_name',
          label: 'Наименование'
        },
        {
          field: 'short_name',
          label: 'Сокращение'
        },
        {
          field: 'code',
          label: 'Код'
        }
      ],
      linkedColumns: [
        {
          field: 'bound',
          label: 'Действие',
          type: 'bound'
        },
        {
          field: 'editor_fio',
          label: 'Кто изменил'
        },
        {
          field: 'action_tstamp',
          type: 'date',
          label: 'Время изменения'
        },
        {
          field: 'value.short_name',
          label: 'Сокращение'
        }
      ],
      form: {
        id: null,
        fio: null,
        prefix: '',
        is_person: true,
        photo_file_id: null,
        is_deleted: null
      },
      rules: {
        fio: [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.name.includes('create')) {
      this.isCreate = true
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getViceGovernor(this.$route.params.id).then(response => {
        this.form = response.data
        this.iogvData = response.data.linkedIogvs
      }).finally(() => {
        this.loading = false
      })
      getViceGovernorHistory(this.$route.params.id).then(response => {
        this.historyData = response.data.model
        this.linkedHistoryData = response.data.linkedModels
      })
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockSaveButton = true
          saveViceGovernor(this.form).then((response) => {
            this.$message({
              message: 'Успешно сохранено!',
              type: 'success'
            })
            if (this.isCreate) {
              this.$router.push({ name: this.$route.name.replace('create', 'view'), params: { id: response.data.id } })
            } else {
              this.fetchData()
            }
          }).finally(() => {
            this.lockSaveButton = false
          })
        }
      })
    },
    deleteActivity () {
      this.lockDeleteButton = true
      deleteViceGovernor(this.form.id).then(() => {
        this.$message({
          message: 'Успешно удалено!',
          type: 'success'
        })
        this.fetchData()
      }).finally(() => {
        this.lockDeleteButton = false
      })
    },
    recoverActivity () {
      this.lockRecoveryButton = true
      recoverViceGovernor(this.form.id).then(() => {
        this.$message({
          message: 'Успешно восстановлено!',
          type: 'success'
        })
        this.fetchData()
      }).finally(() => {
        this.lockRecoveryButton = false
      })
    }
  }
}
</script>

<style scoped>

</style>
