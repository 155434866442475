<template>
  <div>
    <el-upload
      :v-loading="loading"
      ref="file"
      action="/backend/file/upload"
      :limit="limit"
      :before-remove="beforeRemove"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      list-type="picture"
      :headers="{Authorization: 'Bearer ' + token}"
      :file-list="uploadedFiles"
      :on-success="fileUploaded">
      <el-button
        slot="trigger"
        size="small"
        type="primary">
        Выберите файл
      </el-button>
      <div
        slot="tip"
        class="el-upload__tip">
        png/jpg Файлы размером до 16 Мб. Размер изображения 182х250
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>

import { deleteFile, fetchList, downloadFile } from '@/api/file'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      loading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      uploadedFiles: []
    }
  },
  computed: {
    ...mapGetters(['token']),
    fileSpell () {
      return this.limit === 1 ? 'файла' : 'файлов'
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      if (this.value) {
        this.loading = true
        const fileIds = Array.isArray(this.value) ? this.value : [this.value]
        fetchList(fileIds).then(({ data }) => {
          for (const file of data) {
            downloadFile(file.id).then(({ data }) => {
              file.url = window.URL.createObjectURL(new Blob([data]))
              this.loading = false
            })
          }
          this.uploadedFiles = data
        })
      }
    },
    handleRemove (file) {
      if (file.id) {
        deleteFile(file.id)
        this.$emit('delete', null)
      }
    },
    handleExceed () {
      this.$message.warning(`Можно приложить не больше ${this.limit} ${this.fileSpell}`)
    },
    beforeRemove (file) {
      return this.$confirm(`Вы уверены, что хотите удалить ${file.name}?`)
    },
    fileUploaded (data) {
      this.$emit('input', data)
    },
    handlePreview (file) {
      this.dialogVisible = true
      this.dialogImageUrl = file.url
    }
  }
}
</script>
