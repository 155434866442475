import request from '@/utils/request'

export function getClassifiers (params) {
  return request({
    url: '/backend/pki-classification/list',
    method: 'post',
    data: params
  })
}

export function getClassifier (id) {
  return request({
    url: '/backend/pki-classification/detail',
    method: 'post',
    data: { id }
  })
}

export function saveClassifier (form) {
  return request({
    url: '/backend/pki-classification/save/',
    method: 'post',
    data: form
  })
}

export function getUnits (params) {
  return request({
    url: '/backend/unit-of-measurement/list',
    method: 'post',
    data: params
  })
}

export function getUnit (id) {
  return request({
    url: '/backend/unit-of-measurement/detail',
    method: 'post',
    data: { id }
  })
}

export function saveUnit (form) {
  return request({
    url: '/backend/unit-of-measurement/save/',
    method: 'post',
    data: form
  })
}

export function getMainDirectionActivities (params) {
  return request({
    url: '/backend/main-direction-activity/list',
    method: 'post',
    data: params
  })
}

export function getMainDirectionActivity (id) {
  return request({
    url: '/backend/main-direction-activity/detail',
    method: 'post',
    data: { id }
  })
}

export function getMainDirectionActivityHistory (id) {
  return request({
    url: '/backend/main-direction-activity/history',
    method: 'post',
    data: { id }
  })
}

export function saveMainDirectionActivity (form) {
  return request({
    url: '/backend/main-direction-activity/save/',
    method: 'post',
    data: form
  })
}

export function deleteMainDirectionActivity (id) {
  return request({
    url: '/backend/main-direction-activity/delete/',
    method: 'post',
    data: { id }
  })
}

export function recoverMainDirectionActivity (id) {
  return request({
    url: '/backend/main-direction-activity/recover/',
    method: 'post',
    data: { id }
  })
}

export function getViceGovernors (params) {
  return request({
    url: '/backend/vice-governor/list',
    method: 'post',
    data: params
  })
}

export function getViceGovernor (id) {
  return request({
    url: '/backend/vice-governor/detail',
    method: 'post',
    data: { id }
  })
}

export function getViceGovernorHistory (id) {
  return request({
    url: '/backend/vice-governor/history',
    method: 'post',
    data: { id }
  })
}

export function saveViceGovernor (form) {
  return request({
    url: '/backend/vice-governor/save/',
    method: 'post',
    data: form
  })
}

export function deleteViceGovernor (id) {
  return request({
    url: '/backend/vice-governor/delete/',
    method: 'post',
    data: { id }
  })
}

export function recoverViceGovernor (id) {
  return request({
    url: '/backend/vice-governor/recover/',
    method: 'post',
    data: { id }
  })
}

export function getIogvs (params) {
  return request({
    url: '/backend/iogv/list',
    method: 'post',
    data: params
  })
}

export function getIogv (id) {
  return request({
    url: '/backend/iogv/detail',
    method: 'post',
    data: { id }
  })
}

export function getIogvHistory (id) {
  return request({
    url: '/backend/iogv/history',
    method: 'post',
    data: { id }
  })
}

export function saveIogv (form) {
  return request({
    url: '/backend/iogv/save/',
    method: 'post',
    data: form
  })
}

export function deleteIogv (id) {
  return request({
    url: '/backend/iogv/delete/',
    method: 'post',
    data: { id }
  })
}

export function recoverIogv (id) {
  return request({
    url: '/backend/iogv/recover/',
    method: 'post',
    data: { id }
  })
}
